import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const StyledGoalBarContainer = styled(Box)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  height: 2rem;
  width: 100%;
`;
