import React from 'react';
import { useParams } from 'react-router';
import { DONATE_PATH } from 'view/routes';
import QRCode from 'view/components/QRCode';

export default function QRCodeDonatePage() {
  const { contentCreatorName = '' } = useParams();
  const link = `${process.env.REACT_APP_UI_URL}${DONATE_PATH}/${contentCreatorName}`;

  return <QRCode value={link} />;
}
