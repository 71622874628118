import {
  createContext,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
  Dispatch,
} from 'react';
import { listDonations } from 'services/api/donations';
import { PaginatedResponse } from 'services/api/types';
import { User } from 'types/users';
import { Donation } from 'types/donations';
import useAuth from 'hooks/auth';
import { getMe, getOBSUrl } from 'services/api/user';
import { Withdrawal } from 'types/withdrawal';
import { Invoice } from 'types/invoices';
import { listInvoices } from 'services/api/invoices';

export type UserData = {
  isLoading: boolean;
  donationsData: PaginatedResponse<Donation>;
  withdrawalsData: PaginatedResponse<Withdrawal>;
  invoicesData: PaginatedResponse<Invoice>;
  user: User;
  OBSUrl: string;
};

export type UserContextType = UserData & {
  setUser: (user: User) => void;
  setUserData: Dispatch<SetStateAction<UserData>>;
  setDonationsData: (donations: PaginatedResponse<Donation>) => void;
  setWithdrawalsData: (withdrawals: PaginatedResponse<Withdrawal>) => void;
  setInvoicesData: (invoices: PaginatedResponse<Invoice>) => void;
};

export const UserContext = createContext<UserContextType>({
  isLoading: true,
  donationsData: {} as PaginatedResponse<Donation>,
  withdrawalsData: {} as PaginatedResponse<Withdrawal>,
  invoicesData: {} as PaginatedResponse<Invoice>,
  user: {} as User,
  OBSUrl: '',
  setUser: () => {},
  setUserData: () => {},
  setDonationsData: () => {},
  setWithdrawalsData: () => {},
  setInvoicesData: () => {},
});

type Props = {
  children: ReactElement;
};

const initialData = {
  isLoading: true,
  user: {} as User,
  OBSUrl: '',
  donationsData: {} as PaginatedResponse<Donation>,
  withdrawalsData: {} as PaginatedResponse<Withdrawal>,
  invoicesData: {} as PaginatedResponse<Invoice>,
};

export const UserProvider = ({ children }: Props) => {
  const { isAuthenticated, setErrorMessage } = useAuth();
  const [userData, setUserData] = useState<UserData>(initialData);

  const setUser = (user: User = {} as User) =>
    setUserData((prevState) => ({ ...prevState, user }));

  const setDonationsData = (donationsData: PaginatedResponse<Donation>) =>
    setUserData((prevState) => ({
      ...prevState,
      donationsData: {
        ...prevState.donationsData,
        items: [...donationsData.items, ...prevState.donationsData.items],
        links: { ...prevState.donationsData.links, ...donationsData.links },
        meta: { ...prevState.donationsData.meta, ...donationsData.meta },
      },
    }));

  const setWithdrawalsData = (
    withdrawalsData: PaginatedResponse<Withdrawal>,
  ) => {
    if (!userData?.withdrawalsData?.items?.length) {
      return setUserData((prevState) => ({ ...prevState, withdrawalsData }));
    }
    return setUserData((prevState) => ({
      ...prevState,
      withdrawalsData: {
        ...prevState.withdrawalsData,
        items: [...withdrawalsData.items, ...prevState.withdrawalsData.items],
        links: { ...prevState.withdrawalsData.links, ...withdrawalsData.links },
        meta: { ...prevState.withdrawalsData.meta, ...withdrawalsData.meta },
      },
    }));
  };

  const setInvoicesData = (invoicesData: PaginatedResponse<Invoice>) =>
    setUserData((prevState) => ({
      ...prevState,
      invoicesData: {
        ...prevState.invoicesData,
        items: [...invoicesData.items, ...prevState.invoicesData.items],
        links: { ...prevState.invoicesData.links, ...invoicesData.links },
        meta: { ...prevState.invoicesData.meta, ...invoicesData.meta },
      },
    }));

  useEffect(() => {
    if (!isAuthenticated) {
      return setUserData({ ...initialData, isLoading: false });
    }

    const getCurrentUserData = async () => {
      try {
        setUserData((prevState) => ({ ...prevState, isLoading: true }));
        const [user, OBSUrl, donationsData, invoicesData] = await Promise.all([
          getMe(),
          getOBSUrl(),
          listDonations(),
          listInvoices(),
        ]);

        return setUserData({
          isLoading: false,
          donationsData,
          withdrawalsData: {} as PaginatedResponse<Withdrawal>,
          invoicesData,
          user,
          OBSUrl,
        });
      } catch (err) {
        if (err instanceof Error) {
          setErrorMessage(err.message);
        }
        return setUserData({
          isLoading: false,
          donationsData: {} as PaginatedResponse<Donation>,
          withdrawalsData: {} as PaginatedResponse<Withdrawal>,
          invoicesData: {} as PaginatedResponse<Invoice>,
          user: {} as User,
          OBSUrl: '',
        });
      }
    };

    getCurrentUserData();
  }, [isAuthenticated]);

  const value = {
    ...userData,
    setUser,
    setDonationsData,
    setWithdrawalsData,
    setInvoicesData,
    setUserData,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
