import React from 'react';

type VoiceMagicIconProps = {
  fontSize?: number;
};

export default function VoiceMagicIcon({ fontSize = 24 }: VoiceMagicIconProps) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={fontSize}
      height={fontSize}
      viewBox="0 0 24 24"
    >
      <path
        style={{ display: 'none', fill: 'none' }}
        d={`M0,0h${fontSize}v${fontSize}H0V0z`}
      />
      <path
        fill="currentColor"
        d="M21.4,7.4L20,8l1.4,0.6L22,10l0.6-1.4L24,8l-1.4-0.6L22,6L21.4,7.4z M19,6l0.9-2.1L22,3l-2.1-0.9L19,0l-0.9,2.1
	L16,3l2.1,0.9L19,6z"
      />
      <path
        fill="currentColor"
        d="M1,11.2v6h4l5,5v-16l-5,5H1z M14.5,14.2c0-1.8-1-3.3-2.5-4v8.1C13.5,17.5,14.5,16,14.5,14.2z M12,5.5v2.1
	c2.9,0.9,5,3.5,5,6.7s-2.1,5.9-5,6.7V23c4-0.9,7-4.5,7-8.8S16,6.4,12,5.5z"
      />
    </svg>
  );
}
