import React, { ReactElement } from 'react';
import { StyledDrawer } from './styled';

type DrawerProps = {
  open: boolean;
  onClose: () => void;
  action: ReactElement;
  width?: string | object;
  variant: 'permanent' | 'persistent' | 'temporary' | undefined;
  height?: string;
  anchor?: 'right' | 'left' | 'top' | 'bottom';
};

export default function Drawer({
  open: propOpen,
  onClose,
  action,
  width = '80vw',
  variant,
  height = '100vh',
  anchor = 'left',
}: DrawerProps) {
  return (
    <StyledDrawer
      onClose={() => {
        onClose();
      }}
      variant={variant}
      anchor={anchor}
      PaperProps={{
        sx: { width, overflow: 'visible', height },
      }}
      open={propOpen}
    >
      {action}
    </StyledDrawer>
  );
}
