import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { dateFnsLocales } from 'i18n';

export const useDateFormatter = () => {
  const { i18n } = useTranslation();

  return (date: Date | number, dateFormat: string) => {
    const locale = dateFnsLocales[i18n.language] || dateFnsLocales.uk;
    return format(date, dateFormat, { locale });
  };
};
