import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { black4, black71, primary } from 'view/theme/colors';
import useTheme from 'hooks/theme';
import { alpha } from '@mui/material/styles';

export const StyledDonationCard = styled(Box)(() => {
  const { isDarkMode } = useTheme();

  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${isDarkMode ? black71 : black4}`,
    borderTop: `1px solid ${isDarkMode ? black71 : black4}`,
    padding: '0.5rem 1.25rem',
    marginBottom: '0.5rem',
    ...(isDarkMode && {
      outlineColor: black71,
    }),
    gap: '0.5rem',

    '&:hover': {
      borderColor: primary,
      boxShadow: `0 8px 24px 0 ${alpha(primary, 0.2)}`,
    },
  };
});
