import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useSocket from 'hooks/socket';
import { getGoal } from 'services/api/goals';
import { Goal } from 'types/goals';
import Alert from 'view/components/Alert';
import GoalBar from 'view/components/GoalBar';
import { Donation } from 'types/donations';
import { useTranslation } from 'react-i18next';
import { User } from 'types/users';

export default function GoalPage() {
  const { socketIo } = useSocket();
  const { goalId } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [goalData, setGoalData] = useState<
    (Goal & Pick<User, 'cipherId'>) | null
  >(null);

  useEffect(() => {
    const fetchGoalData = async (goalId: string) => {
      try {
        const data = await getGoal(goalId);

        if (!data.active) {
          return setError(t('Goal is not active'));
        }

        setGoalData(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
      }
    };

    if (goalId) {
      fetchGoalData(goalId);
    }
  }, [goalId]);

  useEffect(() => {
    if (socketIo && goalData?.cipherId) {
      socketIo.emit('join', goalData?.cipherId);

      socketIo.on('donation', (donation: Donation) => {
        if (donation.goalId === goalId) {
          setGoalData((prevGoalData) => {
            if (!prevGoalData) {
              return null;
            }
            return {
              ...prevGoalData,
              accumulated: prevGoalData.accumulated + donation.amount,
            };
          });
        }
      });

      return () => {
        socketIo.off('donation');
      };
    }
  }, [socketIo, goalData?.cipherId]);

  return (
    <>
      <Alert open={!!error} type="error" label={error} />
      {goalData && <GoalBar goal={goalData} />}
    </>
  );
}
