import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Donation } from 'types/donations';
import { formatCurrency } from 'tools';
import { StyledDonationCard, StyledDonationMessageBar } from './styled';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { DEFAULT_VOICE_NAME, VOICES_CONFIG } from 'view/constants';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import VoiceMagicIcon from 'view/components/icons/VoiceMagic';
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';

type DonationCardProps = {
  donation: Donation;
  onClick: () => void;
};

export default function DonationCard({ donation, onClick }: DonationCardProps) {
  const [elevated, setElevated] = useState(false);
  const handleMouseEnter = !elevated ? () => setElevated(true) : undefined;
  const handleMouseLeave = () => setElevated(false);

  const { t } = useTranslation();

  return (
    <StyledDonationCard
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledDonationMessageBar>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1">
            {donation.sponsorName ? donation.sponsorName : 'Анонім'} -{' '}
            {formatCurrency(donation.amount, donation.currency)}
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            {donation.voice !== DEFAULT_VOICE_NAME && (
              <Tooltip title={t(VOICES_CONFIG[donation.voice].label)}>
                <Box display="flex" alignItems="center">
                  <VoiceMagicIcon fontSize={18} />
                </Box>
              </Tooltip>
            )}

            <Typography variant="body2" mr={2}>
              {format(new Date(donation.createdAt), 'kk:mm')}
            </Typography>
          </Box>
        </Box>
        {donation.message && (
          <Typography variant="body2">{donation.message}</Typography>
        )}
      </StyledDonationMessageBar>
      {elevated && (
        <Tooltip title={t('Repeat')}>
          <IconButton onClick={onClick}>
            <ReplayIcon />
          </IconButton>
        </Tooltip>
      )}
    </StyledDonationCard>
  );
}
