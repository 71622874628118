import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

type NotFoundPageProps = {
  height?: string;
};

export default function NotFoundPage({ height = 'auto' }: NotFoundPageProps) {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      maxWidth="24rem"
      height={height}
    >
      <Typography lineHeight={1.5} fontSize={144} fontWeight={800}>
        404
      </Typography>
      <Typography lineHeight={1.5} fontSize={36}>
        {t('There’s nothing here')}
      </Typography>
      <Typography variant="body2" textAlign="center">
        {t(
          "Maybe it was a ting, maybe not. Who knows? Anyway, let's call it a 404 and move on.",
        )}
      </Typography>
    </Box>
  );
}
