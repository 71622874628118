import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { StyledLabel } from './styled';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { ReactElement } from 'react';

type UserInfoRowProps = {
  value: string | JSX.Element | null;
  label: string | ReactElement;
  divider?: boolean;
  notEditable?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default function UserInfoRow({
  label,
  value,
  notEditable,
  divider = false,
  onClick,
}: UserInfoRowProps) {
  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ padding: '16px 0', wordBreak: 'break-word' }}
      >
        <Grid size={3}>
          <StyledLabel>{label}</StyledLabel>
        </Grid>
        <Grid size={8}>
          <Box typography="body1" sx={{ fontWeight: 500 }}>
            {value}
          </Box>
        </Grid>
        {!notEditable && (
          <Grid size={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton size="large" onClick={onClick}>
              <KeyboardArrowRightRoundedIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {divider && <Divider />}
    </>
  );
}
