import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import FormHeader from 'view/components/Form/Header';
import { User } from 'types/users';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import Grid from '@mui/material/Grid2';
import useUser from 'hooks/user';
import { ChangeGoalsValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import IconButton from '@mui/material/IconButton';
import { red } from 'view/theme/colors';
import Tooltip from '@mui/material/Tooltip';
import TrashIcon from 'view/components/icons/Trash';
import AddIcon from '@mui/icons-material/Add';
import { Currency, CurrencyLabelMap } from 'services/api/types';
import { Goal } from 'types/goals';
import { bulkCreateGoals, deleteGoal } from 'services/api/goals';
import GoalBar from 'view/components/GoalBar';
import CopyButton from 'view/components/CopyButton';
import { GOALS_PATH } from 'view/routes';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from 'view/components/ConfirmationDialog';

type ChangeGoalsFormData = {
  goals: Array<Omit<Goal, '_id'>>;
};

type ChangeGoalsProps = {
  onCancel: () => void;
  onSaved: ({ goals }: Pick<User, 'goals'>) => void;
};

export default function ChangeGoals({ onCancel, onSaved }: ChangeGoalsProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user, setUser } = useUser();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState<Goal | null>(null);

  const initialFormData = {
    resolver: yupResolver(ChangeGoalsValidationSchema(t)),
    defaultValues: {
      goals: [],
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<ChangeGoalsFormData>(initialFormData);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'goals',
  });

  const watchFields = watch('goals');

  const onSubmit = async (data: ChangeGoalsFormData) => {
    setErrorMessage('');

    try {
      const goals = await bulkCreateGoals(data);
      onSaved({ ...user, goals: [...user.goals, ...goals] });

      reset(data);
      setSuccessMessage(t('Successfully created goals'));
    } catch (err) {
      setErrorMessage(t('Error while creating goals'));
    }
  };

  const onDeleteConfirmation = async (goal: Goal) => {
    try {
      setLoading(true);
      await deleteGoal(goal._id);
      setGoalToDelete(null);
      const filteredGoals = user.goals.filter(
        (userGoal) => userGoal._id !== goal._id,
      );
      setUser({ ...user, goals: filteredGoals });
    } catch (err) {
      setErrorMessage(t('Error while deleting goal'));
    } finally {
      setLoading(false);
    }
  };

  const submitDisabled =
    Boolean(Object.keys(errors).length) || !isDirty || isSubmitting;

  const onAppendClick = () => {
    append({
      start: 0,
      finish: 100,
      accumulated: 0,
      label: '',
      currency: Currency.UAH,
      active: true,
      createdAt: new Date(),
      contentCreatorId: user._id,
    });
  };

  return (
    <StyledFormContainer>
      {goalToDelete && (
        <ConfirmationDialog
          open={!!goalToDelete}
          text={t('deleteGoal', { goal: goalToDelete.label })}
          confirmText={t('Delete')}
          onConfirm={() => onDeleteConfirmation(goalToDelete)}
          onClose={() => setGoalToDelete(null)}
        />
      )}
      <FormHeader
        title={t('Goal widget for OBS')}
        description={t('Add a goal for accumulating donations')}
      />
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography variant="body2" mb={2}>
            {t(
              'OBS settings for the browser source: width 290px (or any other), height 32px',
            )}
          </Typography>
          {user.goals.map((goal) => {
            return (
              <Box
                key={goal._id}
                display="flex"
                alignItems="center"
                gap={2}
                mb={1}
              >
                <GoalBar goal={{ ...goal }} />

                <Box>
                  <Tooltip arrow title={t('Copy OBS link')}>
                    <Box>
                      <CopyButton
                        value={`${process.env.REACT_APP_UI_URL}${GOALS_PATH}/${user.contentCreatorName}/${goal._id}`}
                      />
                    </Box>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip arrow title={t('Remove')}>
                    <IconButton
                      onClick={() => setGoalToDelete(goal)}
                      disabled={loading}
                    >
                      <TrashIcon color={red} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            );
          })}
          <Divider sx={{ marginBottom: 2 }} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" mb={2}>
              {fields.map((field, index) => {
                return (
                  <Box
                    key={field.id}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    mb={2}
                  >
                    <Input
                      name={`goals.${index}.label`}
                      label={t('Goal label')}
                      type="text"
                      error={!!errors.goals?.[index]?.label}
                      helperText={errors.goals?.[index]?.label?.message}
                      control={control}
                    />

                    <Input
                      name={`goals.${index}.start`}
                      label={t('Start')}
                      type="number"
                      error={!!errors.goals?.[index]?.start}
                      helperText={errors.goals?.[index]?.start?.message}
                      control={control}
                      startAdornment={<>{CurrencyLabelMap[Currency.UAH]}</>}
                    />

                    <Input
                      name={`goals.${index}.finish`}
                      label={t('Finish')}
                      type="number"
                      error={!!errors.goals?.[index]?.finish}
                      helperText={errors.goals?.[index]?.finish?.message}
                      control={control}
                      startAdornment={<>{CurrencyLabelMap[Currency.UAH]}</>}
                    />

                    <Tooltip arrow title={t('Remove')}>
                      <IconButton
                        onClick={() => remove(index)}
                        disabled={loading}
                      >
                        <TrashIcon color={red} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              })}
              <Box>
                <Button
                  startIcon={<AddIcon />}
                  onClick={onAppendClick}
                  disabled={user.goals.length + watchFields.length === 5}
                  variant="text"
                  label={t('Add')}
                />
              </Box>
            </Box>
            <StyledFormActions>
              <Button
                withLoader
                type="submit"
                label={t('Save')}
                loading={isSubmitting}
                disabled={submitDisabled}
              />
              <Button
                label={t('Back')}
                variant="text"
                color="secondary"
                onClick={onCancel}
              />
            </StyledFormActions>
          </form>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
}
