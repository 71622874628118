import React, { useRef, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

type AudioPreviewProps = {
  soundUrl: string;
  disabled?: boolean;
  title: string;
};

export default function AudioPreview({
  soundUrl,
  disabled,
  title,
}: AudioPreviewProps) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { t } = useTranslation();

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    setIsPlaying(false);
    const audio = audioRef.current;

    if (audio) {
      const handleEnded = () => setIsPlaying(false);
      audio.addEventListener('ended', handleEnded);

      return () => {
        audio.removeEventListener('ended', handleEnded);
      };
    }
  }, [soundUrl]);

  return (
    <Box>
      <audio ref={audioRef} src={soundUrl} />
      <Tooltip arrow title={t(title)}>
        <IconButton onClick={togglePlay} disabled={disabled}>
          {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );
}
