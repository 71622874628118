import { PaymentMethodTypes, User } from 'types/users';
import { request } from '../request';
import { PaginatedResponse } from 'services/api/types';
import { Donation } from 'types/donations';

export const getMe = async (): Promise<User> => {
  const { data: user } = await request({
    url: '/content-creators/me',
  });
  return user;
};

export const deleteAccount = async (): Promise<void> => {
  await request({
    method: 'delete',
    url: '/content-creators/me',
  });
};

export const getOBSUrl = async (): Promise<string> => {
  const { data } = await request({
    url: '/content-creators/obs-url',
  });

  return data.url;
};

export const updateCipherId = async (): Promise<
  Pick<User, 'cipherId'> & { url: string }
> => {
  const { data } = await request({
    method: 'put',
    url: `/content-creators/obs-url`,
  });
  return data;
};

export const updatePictureProfile = async (
  formData: FormData,
): Promise<Pick<User, 'profilePictureUrl'>> => {
  const { data } = await request({
    method: 'put',
    url: `/content-creators/profile-picture`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

export const deleteProfilePicture = async (): Promise<void> => {
  await request({
    method: 'delete',
    url: `/content-creators/profile-picture`,
  });
};

export const getDonationConfigFromCipher = async (
  cipher: string,
): Promise<
  Pick<
    User,
    | 'donationAnimationFormat'
    | 'minimumVoiceOverThreshold'
    | 'donationThresholdConfig'
    | 'cipherId'
  >
> => {
  const { data } = await request({
    url: `/content-creators/ciphers/${cipher}/donation-config`,
  });

  return data;
};

export const getDonationsFromCipher = async (
  cipher: string,
  url = '/donations',
): Promise<PaginatedResponse<Donation>> => {
  const { data } = await request({
    url: `/content-creators/ciphers/${cipher}${url}`,
  });

  return data;
};

export const getCipherIdFromCipher = async (
  cipher: string,
): Promise<{ cipherId: string }> => {
  const { data } = await request({
    url: `/content-creators/ciphers/${cipher}/cipherId`,
  });

  return data;
};

export const getDonationConfig = async (
  contentCreatorName: string,
): Promise<
  Pick<
    User,
    | 'contentCreatorName'
    | 'minimumDonationAmount'
    | 'minimumVoiceOverThreshold'
    | 'profilePictureUrl'
    | 'donationThresholdConfig'
    | 'socialNetworks'
    | 'goals'
    | 'isPremium'
  > & {
    paymentMethods: PaymentMethodTypes[];
  }
> => {
  const { data } = await request({
    url: `/content-creators/donation-config/${contentCreatorName}`,
  });

  return data;
};

export const checkIsUserNameExist = async (
  contentCreatorName: string,
): Promise<boolean> => {
  const { data } = await request({
    url: `/content-creators/${contentCreatorName}/is-username-exist`,
  });

  return data;
};

export const updateUserData = async (
  userId: string,
  fieldsToUpdate: Partial<User>,
): Promise<void> => {
  await request({
    method: 'put',
    url: `/content-creators/${userId}`,
    data: fieldsToUpdate,
  });
};

export const checkIsAuthenticated = async () => {
  const { data } = await request({
    url: '/auth/is-authenticated',
  });
  return data;
};
