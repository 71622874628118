import React from 'react';
import Box from '@mui/material/Box';
import AudioPreview from 'view/components/AudioPreview';
import styled from '@emotion/styled';
import { primary } from 'view/theme/colors';
import { VoiceConfig } from 'view/constants';
import { useTranslation } from 'react-i18next';

export const StyledAudioButton = styled(Box)`
  display: flex;
  align-items: center;
  border: 1px solid ${primary};
  padding: 0.25rem 2rem 0.25rem 0.25rem;
  gap: 0.5rem;
  border-radius: 2rem;
  width: auto;
`;

type AudioButtonProps = {
  config: VoiceConfig;
  title: string;
};

export default function AudioButton({ config, title }: AudioButtonProps) {
  const { t } = useTranslation();

  return (
    <StyledAudioButton>
      <AudioPreview soundUrl={config.exampleUrl} title={title} />
      {t(config.label)}
    </StyledAudioButton>
  );
}
