import { createTheme } from '@mui/material/styles';
import {
  black54,
  black87,
  black100,
  green,
  lightPrimary,
  primary,
  red,
  white,
  black20,
  lightRed,
  lightGreen,
  darkPrimary,
  darkRed,
  darkGreen,
  black2,
  black38,
  grey,
  lightGray,
  unknownStatus,
} from './colors';

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: lightPrimary,
      dark: darkPrimary,
    },
    secondary: {
      main: grey,
      light: lightGray,
      dark: unknownStatus,
    },
    error: {
      main: red,
      light: lightRed,
      dark: darkRed,
    },
    success: {
      main: green,
      light: lightGreen,
      dark: darkGreen,
      contrastText: white,
    },
    mode: 'light',
  },
  typography: {
    fontFamily: `"Manrope", "Helvetica", "Arial", sans-serif`,
    button: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '24px',
      color: black87,
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '32px',
      color: black87,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '40px',
      color: black87,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '30px',
      color: black100,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '24px',
      color: black87,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '24px',
      color: black54,
    },
    subtitle1: {
      fontSize: '0.75rem',
      fontWeight: 800,
      letterSpacing: 0.2,
      lineHeight: '24px',
      textTransform: 'uppercase',
      color: black54,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          background: black2,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0.5rem',
          boxShadow: 'none',
          padding: '0.75rem 1.5rem',
          fontWeight: 700,
          fontSize: '1rem',
          lineHeight: '24px',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',

          '&:hover': {
            background: lightPrimary,

            p: {
              color: primary,
            },

            svg: {
              color: primary,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: black87,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: black54,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: black54,

          '&:hover': {
            color: primary,
            background: lightPrimary,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: 28,
          span: {
            fontWeight: 500,
            fontSize: '0.875rem',
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: lightPrimary,
      dark: darkPrimary,
    },
    secondary: {
      main: black54,
      light: black38,
      dark: black87,
    },
    error: {
      main: red,
      light: lightRed,
      dark: darkRed,
    },
    success: {
      main: green,
      light: lightGreen,
      dark: darkGreen,
      contrastText: white,
    },
    mode: 'dark',
    background: {
      default: black100,
      paper: black87,
    },
  },
  typography: {
    fontFamily: `"Manrope", "Helvetica", "Arial", sans-serif`,
    button: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '24px',
      color: white,
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '32px',
      color: white,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '40px',
      color: white,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '30px',
      color: white,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '24px',
      color: white,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '24px',
      color: black20,
    },
    subtitle1: {
      fontSize: '0.75rem',
      fontWeight: 800,
      letterSpacing: 0.2,
      lineHeight: '24px',
      textTransform: 'uppercase',
      color: black20,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0.5rem',
          boxShadow: 'none',
          padding: '0.75rem 1.5rem',
          fontWeight: 700,
          fontSize: '1rem',
          lineHeight: '24px',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',

          '&:hover': {
            background: lightPrimary,

            p: {
              color: primary,
            },

            svg: {
              color: primary,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: black54,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: black20,

          '&:hover': {
            color: primary,
            background: lightPrimary,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: 28,
          span: {
            fontWeight: 500,
            fontSize: '0.875rem',
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export type Theme = typeof lightTheme;
