import React from 'react';
import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { primary, blue } from 'view/theme/colors';

const StyledContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const StyledShape = styled(motion.div)`
  position: absolute;
  width: 200px;
  height: 200px;
  background: linear-gradient(135deg, ${primary}, ${blue});
  border-radius: 40%;
  filter: blur(35px);
  will-change: transform, opacity;
`;

type BackgroundAnimationProps = {
  x: number;
  y: number;
};

export default function BackgroundAnimation({
  x,
  y,
}: BackgroundAnimationProps) {
  const generateShapes = () => {
    return Array.from({ length: 5 }, (_, i) => {
      return (
        <StyledShape
          key={`shape-${i}`}
          className="shape"
          initial={{
            x: Math.random() * x - 50 + '%',
            y: Math.random() * y - 50 + '%',
            scale: Math.random() * 0.5 + 0.5,
            opacity: 0.4,
          }}
          animate={{
            x: Math.random() * x - 50 + '%',
            y: Math.random() * y - 50 + '%',
            scale: Math.random() * 1.5 + 0.5,
            opacity: 0.2,
          }}
          transition={{
            duration: Math.random() * 10 + 3,
            repeat: Infinity,
            repeatType: 'mirror',
            ease: 'easeInOut',
          }}
        />
      );
    });
  };

  return <StyledContainer>{generateShapes()}</StyledContainer>;
}
