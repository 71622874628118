import { HeadCell } from 'view/components/Table/components/TableHeader';
import { User } from 'types/users';

export const headCells: HeadCell<User>[] = [
  {
    id: 'profilePictureUrl',
    numeric: false,
    disablePadding: true,
    label: 'User',
    isSortable: true,
    width: 300,
  },
  {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
    isSortable: false,
    width: 300,
  },
  {
    id: 'isPremium',
    numeric: false,
    disablePadding: false,
    label: 'Is Premium',
    isSortable: false,
    width: 110,
  },
  {
    id: 'balance',
    numeric: true,
    disablePadding: false,
    label: 'Balance',
    isSortable: true,
    width: 120,
  },
];
