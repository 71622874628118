import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import Input from 'view/components/Form/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeWayforpayValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/user';
import { PaymentMethodTypes } from 'types/users';
import Box from '@mui/material/Box';
import PasswordInput from 'view/components/Form/PasswordInput';

export type WayforpayFormData = {
  merchantAccount: string;
  merchantSecretKey: string;
};

type WayforpayDialogProps = {
  open: boolean;
  onSaved: (data: WayforpayFormData | true) => void;
  onClose: () => void;
};

export default function WayforpayDialog({
  open,
  onSaved,
  onClose,
}: WayforpayDialogProps) {
  const { user } = useUser();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const initialFormData = {
    resolver: yupResolver(ChangeWayforpayValidationSchema(t)),
    defaultValues: {
      merchantAccount:
        user.paymentMethods?.[PaymentMethodTypes.WAYFORPAY]?.merchantAccount ||
        '',
      merchantSecretKey:
        user.paymentMethods?.[PaymentMethodTypes.WAYFORPAY]
          ?.merchantSecretKey || '',
    },
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<WayforpayFormData>(initialFormData);

  const onSubmit = async (data: WayforpayFormData) => {
    setLoading(true);
    onSaved(data);
  };

  return (
    <ConfirmationDialog
      open={open}
      text={t('Please enter your Wayforpay merchant credentials')}
      color="primary"
      loading={loading}
      disabled={loading}
      withLoader
      confirmText={t('Save')}
      onConfirm={() => handleSubmit(onSubmit)()}
      onClose={onClose}
    >
      <form>
        <Box mb={2}>
          <Input
            name="merchantAccount"
            label={t('Merchant account')}
            type="text"
            error={!!errors.merchantAccount?.message}
            helperText={errors.merchantAccount?.message}
            control={control}
          />
        </Box>
        <Box mb={2}>
          <PasswordInput
            name="merchantSecretKey"
            label={t('Merchant secret key')}
            error={!!errors.merchantSecretKey?.message}
            helperText={errors.merchantSecretKey?.message}
            control={control}
          />
        </Box>
        {/*<Tooltip*/}
        {/*  arrow*/}
        {/*  title={t(*/}
        {/*    `Monthly limit of withdrawals is ${formatUAHCurrency(*/}
        {/*      MAX_UAH_AMOUNT,*/}
        {/*    )}`,*/}
        {/*  )}*/}
        {/*>*/}
        {/*  <Box>*/}
        {/*    <Button*/}
        {/*      onClick={() => onSaved(true)}*/}
        {/*      fullWidth*/}
        {/*      color="error"*/}
        {/*      label="Continue without credentials"*/}
        {/*      variant="text"*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*</Tooltip>*/}
      </form>
    </ConfirmationDialog>
  );
}
