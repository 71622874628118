import styled from '@emotion/styled';
import { NavLink } from 'react-router';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    height: 0.125rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .MuiTab-root {
    margin-right: 1rem;
    overflow: visible;
    text-transform: none;
    padding: 1.5rem 0 1.5rem 0;

    &.Mui-selected {
      font-weight: 700;
    }
  }
`;

const StyledTabsWithIcons = styled(Tabs)`
  z-index: 100;

  .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
  }

  .MuiTabs-indicator {
    height: 0.1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .MuiTab-root {
    margin-right: 0.5rem;
    overflow: visible;
    text-transform: none;
    font-size: 0.875rem;
    font-weight: 400;
    width: 3rem;

    &.Mui-selected {
      font-weight: 700;
    }
  }
`;

type TabT = {
  id: string;
  onClick: () => void;
  icon?: JSX.Element;
  label?: string;
  disabled?: boolean;
};

type NavigationTabsProps = {
  withIcons?: boolean;
  tabs: TabT[];
};

export default function NavigationTabs({
  withIcons,
  tabs,
}: NavigationTabsProps) {
  let { pathname } = window.location;

  if (withIcons) {
    return (
      <StyledTabsWithIcons
        value={pathname}
        variant="scrollable"
        scrollButtons={true}
        aria-label="navigation-tabs"
      >
        {tabs.map(({ icon, onClick, id, disabled, label }) => (
          <Tab
            key={id}
            icon={icon}
            aria-label={id}
            value={id}
            label={label}
            disabled={disabled}
            onClick={onClick}
            LinkComponent={NavLink}
            disableRipple
          />
        ))}
      </StyledTabsWithIcons>
    );
  }

  return (
    <StyledTabs
      value={pathname}
      variant="scrollable"
      scrollButtons={true}
      aria-label="navigation-tabs"
    >
      {tabs.map(({ label, onClick, id, disabled }) => (
        <Tab
          key={id}
          aria-label={id}
          value={id}
          label={label}
          disabled={disabled}
          onClick={onClick}
          LinkComponent={NavLink}
          disableRipple
        />
      ))}
    </StyledTabs>
  );
}
