import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ActionMenu, { MenuItemType } from 'view/components/ActionMenu';
import ListIcon from 'view/components/icons/List';
import { PaymentMethodTypes, User } from 'types/users';
import Loader from 'view/components/Loader';
import TablePagination from 'view/components/Table/components/TablePagination';
import TableHeader, {
  Order,
} from 'view/components/Table/components/TableHeader';
import { headCells } from './helpers';
import {
  PaginatedResponse,
  PaymentMethodsCurrencyMap,
} from 'services/api/types';
import Avatar from 'view/components/Avatar';
import TrashIcon from 'view/components/icons/Trash';
import CopyButton from 'view/components/CopyButton';
import { formatCurrency } from 'tools';
import Chip from '@mui/material/Chip';
import { PAYMENT_METHOD_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangePaymentMethods';

type UsersTableProps = {
  setSelectedUserId: (userId: string) => void;
  setSorting: (sorting: [string, Order]) => void;
  onClickDeleteUser: (device: User) => void;
  onPageChange: (_event: unknown, newPage: number) => void;
  loading: boolean;
  data: PaginatedResponse<User>;
  page: number;
  sorting: [string, Order];
};

const UsersTable = ({
  loading,
  setSelectedUserId,
  data,
  page,
  sorting,
  onPageChange,
  setSorting,
  onClickDeleteUser,
}: UsersTableProps) => {
  const rowsPerPage = 8;
  const orderBy = sorting[0];
  const order = sorting[1];

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof User,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setSorting([property, isAsc ? 'desc' : 'asc']);
  };

  if (!data.items.length) {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography variant="body2">No users found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ boxShadow: 'none', background: 'none' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            {loading ? (
              <TableRow
                sx={{
                  'th,td': { borderBottom: 'none' },
                  height: 73 * rowsPerPage,
                }}
              >
                <TableCell colSpan={6}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              <>
                <TableHeader
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={data.meta.totalItems}
                />
                <TableBody>
                  {data.items.map((row: User, index: number) => {
                    const menuItems: MenuItemType[] = [
                      {
                        label: 'Profile',
                        icon: <ListIcon />,
                        onClick: () => setSelectedUserId(row._id),
                      },
                      {
                        label: 'Delete',
                        icon: <TrashIcon />,
                        onClick: () => onClickDeleteUser(row),
                        className: 'error',
                      },
                    ];

                    return (
                      <TableRow
                        sx={
                          index + 1 === data.items.length
                            ? {
                                'th,td': { borderBottom: 'none' },
                              }
                            : undefined
                        }
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          sx={{ padding: '1rem 0' }}
                        >
                          <Box
                            display="flex"
                            sx={{ alignItems: 'center' }}
                            gap={1}
                          >
                            <Avatar
                              contentCreatorName={row.contentCreatorName}
                              src={row.profilePictureUrl}
                              size={2}
                            />
                            <Typography variant="body2">
                              {row.contentCreatorName}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="left">
                          <Box display="flex" alignItems="center" gap={1}>
                            {row._id}
                            <CopyButton value={row._id} />
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Chip
                            icon={
                              row.isPremium ? (
                                <CheckCircleIcon />
                              ) : (
                                <CancelIcon />
                              )
                            }
                            label={row.isPremium ? 'Yes' : 'No'}
                            variant="filled"
                            color={row.isPremium ? 'success' : 'secondary'}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Box display="flex" alignItems="center" gap={1}>
                            {Object.keys(row.balance).map((key) => {
                              const paymentMethod = key as PaymentMethodTypes;
                              return (
                                <Chip
                                  key={paymentMethod}
                                  label={`${
                                    PAYMENT_METHOD_MEDIA_MAP[paymentMethod]
                                      .shortLabel
                                  } ${formatCurrency(
                                    row.balance[paymentMethod],
                                    PaymentMethodsCurrencyMap[paymentMethod],
                                  )}`}
                                  variant="outlined"
                                />
                              );
                            })}
                          </Box>
                        </TableCell>
                        <TableCell align="right" sx={{ paddingRight: 0 }}>
                          <ActionMenu
                            small
                            buttonIcon={<MoreVertIcon />}
                            menuItems={menuItems}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          totalItems={data.meta.totalItems}
          totalPages={data.meta.totalPages}
          onPageChange={onPageChange}
          rowsPerPage={data.meta.itemsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default UsersTable;
