import React, { useState } from 'react';
import Input from 'view/components/Input';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/user';
import { PaymentMethodTypes } from 'types/users';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from 'view/components/icons/Edit';
import EyeOffIcon from 'view/components/icons/EyeOff';
import EyeIcon from 'view/components/icons/Eye';

type WayforpayDataRowProps = {
  setPaymentMethodToUpdate: (data: PaymentMethodTypes | null) => void;
};

export default function WayforpayDataRow({
  setPaymentMethodToUpdate,
}: WayforpayDataRowProps) {
  const { user } = useUser();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  if (!user.paymentMethods[PaymentMethodTypes.WAYFORPAY]?.merchantSecretKey) {
    return null;
  }
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
      <Box width="18rem">
        <Input
          id="merchant-account"
          label={t('Merchant account')}
          fullWidth
          disabled
          type="text"
          value={
            user.paymentMethods[PaymentMethodTypes.WAYFORPAY]?.merchantAccount
          }
        />
      </Box>
      <Box width="18rem">
        <Input
          id="merchant-secret"
          label={t('Merchant secret key')}
          fullWidth
          disabled
          type={showPassword ? 'text' : 'password'}
          value={
            user.paymentMethods[PaymentMethodTypes.WAYFORPAY]?.merchantSecretKey
          }
          endAdornment={
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <EyeOffIcon /> : <EyeIcon />}
            </IconButton>
          }
        />
      </Box>
      <Tooltip arrow title={t('Update the payment method')}>
        <IconButton
          onClick={() => setPaymentMethodToUpdate(PaymentMethodTypes.WAYFORPAY)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
