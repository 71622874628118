import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { black4, black71, primary } from 'view/theme/colors';
import useTheme from 'hooks/theme';
import { alpha } from '@mui/material/styles';

export const StyledDonationCard = styled(Box)(() => {
  const { isDarkMode } = useTheme();

  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '0.5rem',
    outline: `1px solid ${black4}`,
    padding: '0 1rem',
    marginBottom: '0.5rem',
    ...(isDarkMode && {
      outlineColor: black71,
    }),

    '&:hover': {
      outline: `1px solid ${primary}`,
      boxShadow: `0 8px 24px 0 ${alpha(primary, 0.2)}`,
    },
  };
});

export const StyledDonationMessageBar = styled(Box)`
  flex-grow: 1;
  padding: 0.5rem 0;
  word-break: break-word;
`;
