import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'view/components/PageHeader';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import { deleteAccount as deleteAccountRequest } from 'services/api/user';
import BackButton from 'view/components/BackButton';
import Button from 'view/components/Button';
import TrashIcon from 'view/components/icons/Trash';
import UserInfoRow from 'view/components/UserInfoRow';
import { StyledContainer } from './styled';
import ChangeDonationDesign from 'view/pages/AccountSettings/components/ChangeDonationDesign';
import ChangeMinimumDonationAmount from 'view/pages/AccountSettings/components/ChangeMinimumDonationAmount';
import ChangeDonationThresholdConfig from 'view/pages/AccountSettings/components/ChangeDonationThresholdConfig';
import ChangeUsername from 'view/pages/AccountSettings/components/ChangeUsername';
import ChangePaymentMethods, {
  PAYMENT_METHOD_MEDIA_MAP,
} from 'view/pages/AccountSettings/components/ChangePaymentMethods';
import useUser from 'hooks/user';
import useAuth from 'hooks/auth';
import { formatCurrency } from 'tools';
import Avatar from 'view/components/Avatar';
import ChangeProfilePicture from 'view/pages/AccountSettings/components/ChangeProfilePicture';
import ChangeSocialNetworks from 'view/pages/AccountSettings/components/ChangeSocialNetworks';
import Chip from '@mui/material/Chip';
import { PaymentMethodsCurrencyMap } from 'services/api/types';
import { PaymentMethodTypes } from 'types/users';
import ChangeMinimumDonationVoiceOver from 'view/pages/AccountSettings/components/ChangeMinimumDonationVoiceOver';
import Badge from '@mui/material/Badge';

export default function AccountSettings() {
  const { signOut, setErrorMessage } = useAuth();
  const { user, setUser } = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState<string | null>(null);
  const { t } = useTranslation();

  const deleteAccount = async () => {
    setLoading(true);
    try {
      await deleteAccountRequest();
      signOut();
    } catch (err) {
      setErrorMessage(t('Error while deleting account'));
    } finally {
      setLoading(false);
    }
  };

  const clearFieldToEdit = () => setFieldToEdit(null);

  const renderEditForm = (fieldToEdit: string) => {
    if (fieldToEdit === 'profilePicture') {
      return <ChangeProfilePicture onCancel={clearFieldToEdit} />;
    }
    if (fieldToEdit === 'username') {
      return (
        <ChangeUsername
          onCancel={clearFieldToEdit}
          onSaved={({ contentCreatorName }) => {
            setUser({ ...user, contentCreatorName });
          }}
        />
      );
    }
    if (fieldToEdit === 'socialNetworks') {
      return (
        <ChangeSocialNetworks
          onCancel={clearFieldToEdit}
          onSaved={({ socialNetworks }) => {
            setUser({ ...user, socialNetworks });
          }}
        />
      );
    }
    if (fieldToEdit === 'donationDesign') {
      return (
        <ChangeDonationDesign
          onCancel={clearFieldToEdit}
          onSaved={(user) => setUser(user)}
        />
      );
    }

    if (fieldToEdit === 'paymentMethods') {
      return <ChangePaymentMethods onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'minimumDonationAmount') {
      return (
        <ChangeMinimumDonationAmount
          onCancel={clearFieldToEdit}
          onSaved={({ minimumDonationAmount }) => {
            setUser({ ...user, minimumDonationAmount });
          }}
        />
      );
    }

    if (fieldToEdit === 'minimumDonationVoiceOver') {
      return (
        <ChangeMinimumDonationVoiceOver
          onCancel={clearFieldToEdit}
          onSaved={({ minimumVoiceOverThreshold }) => {
            setUser({ ...user, minimumVoiceOverThreshold });
          }}
        />
      );
    }

    if (fieldToEdit === 'donationThresholdConfig') {
      return (
        <ChangeDonationThresholdConfig
          onCancel={clearFieldToEdit}
          onSaved={({ donationThresholdConfig }) => {
            setUser({ ...user, donationThresholdConfig });
          }}
        />
      );
    }
    return null;
  };

  const infoRows = [
    {
      label: t('Profile picture'),
      type: 'profilePicture',
      value: (
        <Avatar
          src={user.profilePictureUrl}
          contentCreatorName={user.contentCreatorName}
        />
      ),
    },
    {
      label: t('Username'),
      type: 'username',
      value: user.contentCreatorName,
    },
    {
      label: (
        <Badge
          badgeContent={
            Object.keys(user?.paymentMethods || {}).length === 0 ? 1 : 0
          }
          variant="dot"
          color="primary"
        >
          {t('Payment methods')}
        </Badge>
      ),
      type: 'paymentMethods',
      value: t('Customize donation payment methods for your audience'),
    },
    {
      label: t('Social networks'),
      type: 'socialNetworks',
      value: t('Links to social networks are displayed on the donation page'),
    },
    {
      label: t('Donation animation'),
      type: 'donationDesign',
      value: t(
        'Customize the animation of donation appearance and disappearance',
      ),
    },
    {
      label: t('Donation voice-over'),
      type: 'minimumDonationVoiceOver',
      value: t(
        'Turn off the voice-overs or set a minimum donation amount with voice-overs',
      ),
    },
    {
      label: t('Minimum donation amount'),
      type: 'minimumDonationAmount',
      value: (
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={1}>
          {Object.keys(user?.minimumDonationAmount || {}).map((key) => {
            const paymentMethod = key as PaymentMethodTypes;
            return (
              <Chip
                key={key}
                label={`${
                  PAYMENT_METHOD_MEDIA_MAP[paymentMethod].shortLabel
                } ${formatCurrency(
                  user.minimumDonationAmount[paymentMethod],
                  PaymentMethodsCurrencyMap[paymentMethod],
                )}`}
                variant="outlined"
              />
            );
          })}
        </Box>
      ),
    },
    {
      label: t('Donation threshold config'),
      type: 'donationThresholdConfig',
      value: t('Setting up the donation threshold, sound, and images'),
    },
  ];

  return (
    <StyledContainer component="main">
      <PageHeader
        title={t('Settings')}
        startAdornment={
          fieldToEdit ? <BackButton onClick={() => setFieldToEdit('')} /> : ''
        }
      />
      {fieldToEdit ? (
        renderEditForm(fieldToEdit)
      ) : (
        <>
          <List>
            {infoRows.map(({ type, label, value = '' }, index) => {
              return (
                <UserInfoRow
                  key={type}
                  label={label}
                  value={value}
                  divider={infoRows.length !== index + 1}
                  onClick={() => setFieldToEdit(type)}
                />
              );
            })}
          </List>
          <Box display="flex" justifyContent="flex-end" mt={6}>
            <Button
              label={t('Delete account')}
              variant="text"
              color="error"
              disabled={loading}
              endIcon={<TrashIcon />}
              onClick={() => setIsDeleteDialogOpen(true)}
            />
          </Box>
        </>
      )}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        loading={loading}
        disabled={loading}
        withLoader
        text={t('Are you sure you want to delete your account?')}
        confirmText={t('Delete')}
        color="error"
        onConfirm={deleteAccount}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
    </StyledContainer>
  );
}
