import React from 'react';
import { StyledGoalBarContainer } from './styled';
import { Goal } from 'types/goals';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { formatCurrency } from 'tools';
import { black71, primary } from 'view/theme/colors';

type GoalBarProps = {
  goal: Goal;
};

export default function GoalBar({ goal }: GoalBarProps) {
  const width = Math.round((goal.accumulated / goal.finish) * 100);
  return (
    <StyledGoalBarContainer
      sx={{
        background: `linear-gradient(90deg, ${primary} ${width}%, ${black71} ${width}%)`,
      }}
    >
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 300 }}>
          {width}%
        </Typography>
      </Box>
      <Box display="flex">
        <Typography variant="body1" mr={1} sx={{ fontWeight: 300 }}>
          {goal.label}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 300 }}>
          {formatCurrency(goal.accumulated, goal.currency)}
        </Typography>
      </Box>

      <Box>
        <Typography
          variant="body1"
          sx={{ alignSelf: 'flex-end', fontWeight: 300 }}
        >
          {formatCurrency(goal.finish, goal.currency)}
        </Typography>
      </Box>
    </StyledGoalBarContainer>
  );
}
