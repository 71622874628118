import { Goal } from 'types/goals';

export type User = {
  _id: string;
  contentCreatorName: string;
  profilePictureUrl: string;
  role?: UserRole;
  createdAt: Date;
  isPremium: boolean;
  balance: { [key in PaymentMethodTypes]: number };
  cipherId: string;
  donationAnimationFormat: DonationAnimationFormat;
  minimumDonationAmount: MinimumDonationAmount;
  minimumVoiceOverThreshold: number;
  donationThresholdConfig: Array<DonationThresholdConfig>;
  paymentMethods: PaymentMethodsConfig;
  socialNetworks: SocialNetwork[];
  goals: Goal[];
};

export type MinimumDonationAmount = {
  [key in PaymentMethodTypes]: number;
};

export enum SocialNetworkTypes {
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
  TIKTOK = 'tiktok',
  TWITCH = 'twitch',
  YOUTUBE = 'youtube',
}

export type SocialNetwork = {
  value: SocialNetworkTypes;
  url: string;
};

export type DonationAnimationFormat = {
  start: string;
  end: string;
  letters: string;
};

export type DonationThresholdConfig = {
  _id: string;
  threshold: number;
  soundUrl: string;
  pictureUrl: string;
};

export enum PaymentMethodTypes {
  WAYFORPAY = 'wayforpay',
  WHITEPAY = 'whitepay',
  CRYPTOMUS = 'cryptomus',
  MONOBANKA = 'monobanka',
  LIQPAY = 'liqpay',
}

interface PaymentMethodsConfig {
  [PaymentMethodTypes.WAYFORPAY]?: {
    merchantAccount: string;
    merchantSecretKey: string;
  };
  [PaymentMethodTypes.WHITEPAY]?: { wallet: string };
  [PaymentMethodTypes.CRYPTOMUS]?: { wallet: string };
  [PaymentMethodTypes.MONOBANKA]?: { monoBankaUrl: string };
  [PaymentMethodTypes.LIQPAY]?: { liqPayApiKey: string };
}

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
}
