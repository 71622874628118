import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

type BackButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
};

export default function BackButton({ onClick, style = {} }: BackButtonProps) {
  return (
    <Box style={style}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        onClick={onClick}
        aria-label="back"
        sx={{ background: 'transparent' }}
      >
        <KeyboardArrowLeftRoundedIcon />
      </IconButton>
    </Box>
  );
}
