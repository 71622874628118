import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatCurrency } from 'tools';
import { Currency } from 'services/api/types';
import { DonationsData, InvoiceDetails, VoiceoverData } from 'types/invoices';
import IconButton from '@mui/material/IconButton';
import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { Donation } from 'types/donations';
import { VOICES_CONFIG } from 'view/constants';
import { useTranslation } from 'react-i18next';
import { PAYMENT_METHOD_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangePaymentMethods';
import { useDateFormatter } from 'hooks/dateFormatter';

type InvoiceDetailsTableProps = {
  data: InvoiceDetails;
};

export default function InvoiceDetailsTable({
  data,
}: InvoiceDetailsTableProps) {
  const { t } = useTranslation();
  const { donationsData, voiceoverData, invoiceTotal } = data;

  return (
    <Paper sx={{ boxShadow: 'none', background: 'none' }}>
      <TableContainer>
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 50 }} />
              <TableCell>{t('Description')}</TableCell>
              <TableCell align="right">{t('Overall')}</TableCell>
              <TableCell align="right">{t('Fee')}</TableCell>
              <TableCell align="right">{t('Discount')}</TableCell>
              <TableCell align="right">{t('Amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <DonationsRow donationsData={donationsData} />
            <VoiceoverRow voiceoverData={voiceoverData} />
            <TableRow>
              <TableCell colSpan={5} align="right">
                {t('Total')}
              </TableCell>
              <TableCell align="right">
                {formatCurrency(invoiceTotal, Currency.UAH)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function DonationsRow({ donationsData }: { donationsData: DonationsData }) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {donationsData.description}
        </TableCell>
        <TableCell align="right">
          {formatCurrency(donationsData.amount, Currency.UAH)}
        </TableCell>
        <TableCell align="right">{donationsData.fee * 100}%</TableCell>
        <TableCell align="right">{donationsData.discount * 100}%</TableCell>
        <TableCell align="right">
          {formatCurrency(donationsData.cost, Currency.UAH)}
        </TableCell>
      </TableRow>
      <TableRow>
        <InnerTable donations={donationsData.donations} open={open} />
      </TableRow>
    </React.Fragment>
  );
}

function VoiceoverRow({ voiceoverData }: { voiceoverData: VoiceoverData }) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {voiceoverData.description}
        </TableCell>
        <TableCell align="right">{voiceoverData.count}</TableCell>
        <TableCell align="right">
          {formatCurrency(voiceoverData.price, Currency.UAH)}
        </TableCell>
        <TableCell align="right">{voiceoverData.discount * 100}%</TableCell>
        <TableCell align="right">
          {formatCurrency(voiceoverData.cost, Currency.UAH)}
        </TableCell>
      </TableRow>
      <TableRow>
        <InnerTable donations={voiceoverData.donations} open={open} />
      </TableRow>
    </React.Fragment>
  );
}

type InnerTableProps = {
  donations: Donation[];
  open: boolean;
};

function InnerTable({ donations, open }: InnerTableProps) {
  const { t } = useTranslation();
  const formatDate = useDateFormatter();

  return (
    <TableCell sx={{ p: 0 }} colSpan={6}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box>
          <Typography ml={2} variant="h6">
            {t('Donations')}
          </Typography>
          {donations.length ? (
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>{t('Date')}</TableCell>
                  <TableCell>{t('Voice')}</TableCell>
                  <TableCell align="right">{t('Voiceover cost')}</TableCell>
                  <TableCell align="right">{t('Payment method')}</TableCell>
                  <TableCell align="right">{t('Amount')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {donations.map((donation) => (
                  <TableRow key={donation._id}>
                    <TableCell>
                      {formatDate(new Date(donation.createdAt), 'd MMMM')}
                    </TableCell>
                    <TableCell>
                      {t(VOICES_CONFIG[donation.voice].label)}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(
                        VOICES_CONFIG[donation.voice].price[donation.currency],
                        donation.currency,
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {
                        PAYMENT_METHOD_MEDIA_MAP[donation.paymentMethod]
                          .shortLabel
                      }
                    </TableCell>
                    <TableCell align="right" component="th" scope="row">
                      {formatCurrency(donation.amount, donation.currency)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography ml={2} mb={2} variant="body2">
              {t('No data')}
            </Typography>
          )}
        </Box>
      </Collapse>
    </TableCell>
  );
}
