import io from 'socket.io-client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getDonationConfigFromCipher } from 'services/api/user';
import Portal from 'view/portals';
import DonationComponent from 'view/components/DonationComponent';
import Alert from 'view/components/Alert';
import { Donation } from 'types/donations';
import { DonationThresholdConfig, User } from 'types/users';
import {
  getIsVoiceOverAllowed,
  getMediaSources,
  handleDonationThresholdConfig,
} from 'tools';
import DonationQueue from 'view/pages/DonationAlert/tools/DonationQueue';
import { AnimatePresence } from 'framer-motion';
import { Currency, ExchangeRate } from 'services/api/types';
import { getExchangeRates } from 'services/api/donations';

export default function DonationAlert() {
  const { cipher } = useParams();
  const [error, setError] = useState('');
  const [donation, setDonation] = useState<Donation | null>(null);
  const [donationConfig, setDonationConfig] = useState<Pick<
    User,
    | 'donationAnimationFormat'
    | 'minimumVoiceOverThreshold'
    | 'donationThresholdConfig'
    | 'cipherId'
  > | null>(null);
  const [exchangeRates, setExchangeRates] = useState<ExchangeRate | null>(null);

  const donationThresholdConfig = handleDonationThresholdConfig(
    donationConfig?.donationThresholdConfig as DonationThresholdConfig[],
    Currency.UAH,
    exchangeRates as ExchangeRate,
  );

  const donationQueue = new DonationQueue(setDonation, setError);

  const addDonationToQueue = (donation: Donation) => {
    if (!donationConfig || !exchangeRates) {
      return;
    }

    const isVoiceOverAllowed = getIsVoiceOverAllowed(
      donation?.amount,
      donationConfig?.minimumVoiceOverThreshold,
      donation?.currency,
      exchangeRates,
    );

    const { alertUrl } = getMediaSources(
      donationThresholdConfig,
      donation?.amount,
      donation.currency,
      exchangeRates,
    );

    donationQueue.addDonation({
      ...donation,
      alertUrl,
      isVoiceOverAllowed,
    });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (cipher) {
        try {
          const config = await getDonationConfigFromCipher(cipher);
          const rates = await getExchangeRates();

          setDonationConfig(config);
          setExchangeRates(rates);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          }
        }
      }
    };

    if (cipher) {
      fetchUserData();
    }
  }, [cipher]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL || '', {
      transports: ['websocket'],
    });
    if (donationConfig?.cipherId) {
      socket.emit('join', donationConfig?.cipherId);
    }

    socket.on('donation', (donation) => {
      addDonationToQueue(donation);
    });

    socket.on('repeat-donation', (donation) => {
      addDonationToQueue(donation);
    });

    socket.on('show-test-donation', (donation) => {
      addDonationToQueue(donation);
    });

    socket.on('stop-donation-queue', () => {
      donationQueue.stop();
    });

    return () => {
      if (socket.connected) {
        socket.off('donation');
        socket.off('repeat-donation');
        socket.off('show-test-donation');
        socket.off('stop-donation-queue');
        socket.disconnect();
      }
      setDonation(null);
    };
  }, [donationConfig?.cipherId]);

  const { pictureUrl } = getMediaSources(
    donationThresholdConfig,
    donation?.amount,
    donation?.currency,
    exchangeRates,
  );

  return (
    <Portal>
      <Alert open={!!error} type="error" label={error} />
      <AnimatePresence>
        {donation && donationConfig?.donationAnimationFormat && (
          <DonationComponent
            imageSrc={pictureUrl}
            animationFormat={donationConfig.donationAnimationFormat}
            donation={donation}
          />
        )}
      </AnimatePresence>
    </Portal>
  );
}
