import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { groupItemsByDate, sortDonationsByDate } from 'tools';
import DonationCard from 'view/components/DonationCard';
import { Donation } from 'types/donations';
import Loader from 'view/components/Loader';
import { useTranslation } from 'react-i18next';
import { useDateFormatter } from 'hooks/dateFormatter';
import useSocket from 'hooks/socket';

type DonationsListProps = {
  donations: Donation[];
  fetchNextDonations: () => void;
  allDonationsLoaded: boolean;
};

export default function DonationsList({
  donations,
  fetchNextDonations,
  allDonationsLoaded,
}: DonationsListProps) {
  const { socketIo } = useSocket();
  const { t } = useTranslation();
  const formatDate = useDateFormatter();

  if (!donations?.length) {
    return <Typography variant="body2">{t('No donations yet.')}</Typography>;
  }

  const donationsGroupedByDate = groupItemsByDate(donations);

  const handleSummaryName = (date: string) => {
    if (['today', 'yesterday'].includes(date)) {
      return t(date);
    }
    return formatDate(new Date(date), 'd MMMM');
  };

  const onPlayClick = (donation: Donation) => {
    if (socketIo) {
      socketIo.emit('repeat-donation', { donation });
    }
  };

  return (
    <InfiniteScroll
      dataLength={donations.length}
      next={fetchNextDonations}
      hasMore={allDonationsLoaded}
      loader={
        <Box my={2}>
          <Loader />
        </Box>
      }
      style={{ overflow: 'visible' }}
    >
      <Box mb={5}>
        {sortDonationsByDate(Object.keys(donationsGroupedByDate))
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
          .map((dateName: string) => (
            <Box mb={2} key={dateName}>
              <Typography variant="subtitle1" mb={0.5}>
                {handleSummaryName(dateName)}
              </Typography>
              {donationsGroupedByDate[dateName]
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime(),
                )
                .map((donation) => (
                  <DonationCard
                    key={donation._id}
                    donation={donation}
                    onClick={() => onPlayClick(donation)}
                  />
                ))}
            </Box>
          ))}
      </Box>
    </InfiniteScroll>
  );
}
