import Box from '@mui/material/Box';
import React, { useState } from 'react';
import useAuth from 'hooks/auth';
import useUser from 'hooks/user';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IBANWithdrawValidationSchema } from 'constraints';
import { createIBANWithdrawalRequest } from 'services/api/withdrawals';
import { Currency, CurrencyLabelMap } from 'services/api/types';
import { PaymentMethodTypes } from 'types/users';
import Typography from '@mui/material/Typography';
import Input from 'view/components/Form/Input';
import Button from 'view/components/Button';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  boxShadow: 24,
  width: '40rem',
};

type AddInvoiceFormData = {
  amount: number;
  createdAt: Date;
  contentCreatorId: string;
};

export default function AddInvoiceModal() {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<AddInvoiceFormData>({
    resolver: yupResolver(IBANWithdrawValidationSchema(t)),
  });

  const onSubmit = async (data: AddInvoiceFormData) => {
    setErrorMessage('');

    try {
      // const response = await createIBANWithdrawalRequest({
      //   ...data,
      //   currency: Currency.UAH,
      // });

      reset();

      setSuccessMessage(t('Successfully created invoice'));
    } catch (err) {
      setErrorMessage(t('Error while creating invoice'));
    }
  };

  return (
    <Box sx={style}>
      <Box mb={2}>
        <Typography variant="body1">{t('Create invoice')}</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={2} mb={3}>
          <Input
            name="amount"
            label={t('Amount')}
            placeholder="100"
            type="number"
            error={!!errors.amount?.message}
            helperText={errors.amount?.message}
            control={control}
            startAdornment={<>{CurrencyLabelMap[Currency.UAH]}</>}
            mb={2}
          />

          <Input
            name="createdAt"
            label={t('Date')}
            placeholder="ФОП Порошенко Володимир Федорович"
            error={!!errors.createdAt?.message}
            helperText={errors.createdAt?.message}
            control={control}
            mb={2}
          />
        </Box>
        <Button
          type="submit"
          label={t('Send')}
          withLoader
          loading={isSubmitting}
          startIcon={<AccountBalanceWalletIcon />}
          fullWidth
        />
      </form>
    </Box>
  );
}
