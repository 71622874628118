import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'view/components/PageHeader';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import BackButton from 'view/components/BackButton';
import UserInfoRow from 'view/components/UserInfoRow';
import { StyledContainer } from './styled';
import useUser from 'hooks/user';
import CopyButton from 'view/components/CopyButton';
import {
  DONATE_PATH,
  LIVE_DONATE_PATH,
  LIVE_HISTORY_PATH,
  QR_CODE_DONATE_PATH,
} from 'view/routes';
import ViewOBSURL from 'view/pages/Widgets/components/ViewOBSURL';
import ViewQRCodeOBSURL from 'view/pages/Widgets/components/ViewQRCodeOBSURL';
import ChangeGoals from 'view/pages/Widgets/components/ChangeGoals';
import ViewTestDonation from 'view/pages/AccountSettings/components/ViewTestDonation';
import ViewLiveHistoryURL from 'view/pages/Widgets/components/ViewLiveHistoryURL';

export default function Widgets() {
  const { user, setUser, OBSUrl } = useUser();

  const [fieldToEdit, setFieldToEdit] = useState<string | null>(null);
  const { t } = useTranslation();

  const clearFieldToEdit = () => setFieldToEdit(null);

  const renderEditForm = (fieldToEdit: string) => {
    if (fieldToEdit === 'obsURL') {
      return <ViewOBSURL onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'liveHistory') {
      return <ViewLiveHistoryURL onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'qrCode') {
      return <ViewQRCodeOBSURL onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'goal') {
      return (
        <ChangeGoals
          onCancel={clearFieldToEdit}
          onSaved={({ goals }) => {
            setUser({ ...user, goals });
          }}
        />
      );
    }

    if (fieldToEdit === 'viewTestDonation') {
      return <ViewTestDonation onCancel={clearFieldToEdit} />;
    }

    return null;
  };

  const infoRows = [
    {
      label: t('OBS link'),
      type: 'obsURL',
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}/${OBSUrl}${LIVE_DONATE_PATH}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>••••••••••••••</Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
    {
      label: t('Donation link'),
      type: 'donationLink',
      notEditable: true,
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}${DONATE_PATH}/${user.contentCreatorName}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>
              <MuiLink
                href={link}
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                {link}
              </MuiLink>
              {'  '}
            </Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
    {
      label: t('Live history'),
      type: 'liveHistory',
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}/${OBSUrl}${LIVE_HISTORY_PATH}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>••••••••••••••</Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
    {
      label: t('QR code widget for OBS'),
      type: 'qrCode',
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}${QR_CODE_DONATE_PATH}/${user.contentCreatorName}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>
              <MuiLink
                href={link}
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                {link}
              </MuiLink>
              {'  '}
            </Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
    {
      label: t('Goal widget for OBS'),
      type: 'goal',
      value: t('Add a goal for accumulating donations'),
    },
    {
      label: t('Test donation'),
      type: 'viewTestDonation',
      value: t('Send a test donate'),
    },
  ];

  return (
    <StyledContainer component="main">
      <PageHeader
        title={t('Widgets')}
        startAdornment={
          fieldToEdit ? <BackButton onClick={() => setFieldToEdit('')} /> : ''
        }
      />
      {fieldToEdit ? (
        renderEditForm(fieldToEdit)
      ) : (
        <>
          <List>
            {infoRows.map(({ type, label, value = '', notEditable }, index) => {
              return (
                <UserInfoRow
                  key={type}
                  label={label}
                  value={value}
                  notEditable={notEditable}
                  divider={infoRows.length !== index + 1}
                  onClick={() => setFieldToEdit(type)}
                />
              );
            })}
          </List>
        </>
      )}
    </StyledContainer>
  );
}
