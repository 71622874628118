import useTheme from 'hooks/theme';
import { black, white } from 'view/theme/colors';

export default function MadeInUkraineLogo() {
  const { isDarkMode } = useTheme();

  const logoColor = isDarkMode ? white : black;

  return (
    <svg
      width="162"
      height="26"
      viewBox="0 0 324 52"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M134.932 13.726C134.989 14.756 135.272 15.627 135.784 16.3433C136.296 17.0574 137.087 17.6106 138.159 18.0007C139.23 18.3908 140.638 18.5869 142.383 18.5869C144.924 18.5869 146.727 18.2856 147.788 17.687C148.849 17.0863 149.382 16.2813 149.382 15.27C149.382 14.5661 149.21 14.0067 148.87 13.5981C148.529 13.1873 147.889 12.8653 146.95 12.6259C146.01 12.3885 144.668 12.2688 142.924 12.2688H138.856V9.26559H142.924C144.46 9.26559 145.645 9.15619 146.479 8.9374C147.313 8.71861 147.887 8.39868 148.201 7.97968C148.515 7.56067 148.67 7.01782 148.67 6.34906C148.67 5.3191 148.172 4.49966 147.177 3.89076C146.182 3.28187 144.536 2.97638 142.24 2.97638C140.591 2.97638 139.253 3.16628 138.229 3.54813C137.205 3.92998 136.451 4.47696 135.966 5.19112C135.483 5.90529 135.194 6.78871 135.099 7.83519H131.543C131.676 5.41404 132.615 3.50272 134.36 2.10122C136.104 0.699717 138.741 0 142.269 0C144.526 0 146.394 0.276586 147.873 0.829755C149.353 1.38292 150.437 2.10741 151.131 3.00322C151.822 3.89902 152.169 4.88977 152.169 5.97753C152.169 7.33156 151.738 8.39455 150.875 9.16445C150.012 9.93641 148.907 10.4462 147.561 10.6939V10.7517C149.078 11.02 150.348 11.5381 151.372 12.3101C152.396 13.082 152.908 14.2111 152.908 15.6993C152.908 16.785 152.553 17.7675 151.841 18.6447C151.131 19.5219 149.991 20.2278 148.426 20.7604C146.861 21.295 144.836 21.5612 142.352 21.5612C138.673 21.5612 135.951 20.8698 134.186 19.4889C132.423 18.106 131.502 16.1864 131.426 13.726H134.925H134.932Z"
        fill={logoColor}
      ></path>
      <path
        d="M168.192 0.344836C169.918 0.344836 171.359 0.598716 172.515 1.10235C173.671 1.60804 174.539 2.32221 175.119 3.24691C175.697 4.17161 175.986 5.2635 175.986 6.52052C175.986 7.77754 175.697 8.8364 175.119 9.75078C174.541 10.6652 173.671 11.3855 172.515 11.9098C171.359 12.4341 169.916 12.6962 168.192 12.6962H160.169V21.2167H156.754V0.342773H168.19L168.192 0.344836ZM168.105 9.72395C169.623 9.72395 170.731 9.44736 171.433 8.89419C172.135 8.34102 172.486 7.55049 172.486 6.52052C172.486 5.49055 172.135 4.70001 171.433 4.14684C170.731 3.59367 169.623 3.31709 168.105 3.31709H160.169V9.72189H168.105V9.72395Z"
        fill={logoColor}
      ></path>
      <path
        d="M190.371 21.5612C188.001 21.5612 185.961 21.1319 184.255 20.2753C182.548 19.4167 181.249 18.2174 180.357 16.6715C179.465 15.1275 179.02 13.307 179.02 11.2099V10.3513C179.02 8.2542 179.465 6.43369 180.357 4.88977C181.249 3.34585 182.548 2.14456 184.255 1.28591C185.963 0.427261 188.001 0 190.371 0C192.741 0 194.837 0.429325 196.544 1.28591C198.251 2.14456 199.55 3.34379 200.442 4.88977C201.334 6.43369 201.78 8.2542 201.78 10.3513V11.2099C201.78 13.307 201.334 15.1275 200.442 16.6715C199.55 18.2154 198.251 19.4167 196.544 20.2753C194.837 21.134 192.78 21.5612 190.371 21.5612ZM190.427 18.5023C192.931 18.5023 194.859 17.8583 196.216 16.5724C197.572 15.2844 198.249 13.4804 198.249 11.1542V10.4111C198.249 8.06637 197.576 6.25618 196.23 4.97852C194.884 3.70087 192.95 3.06307 190.427 3.06307C187.904 3.06307 185.907 3.70706 184.553 4.99297C183.196 6.28095 182.519 8.08701 182.519 10.4111V11.1542C182.519 13.4804 183.196 15.2865 184.553 16.5724C185.909 17.8583 187.866 18.5023 190.427 18.5023Z"
        fill={logoColor}
      ></path>
      <path
        d="M217.295 9.17904C219.873 9.17904 221.812 9.72188 223.113 10.8096C224.411 11.8953 225.062 13.3546 225.062 15.1834C225.062 17.0122 224.411 18.5004 223.113 19.5861C221.814 20.6718 219.873 21.2167 217.295 21.2167H205.801V0.342773H223.439V3.31709H209.187V9.17904H217.295ZM217.21 18.2733C218.765 18.2733 219.888 18.0009 220.581 17.458C221.273 16.9152 221.62 16.1473 221.62 15.1566C221.62 14.1658 221.273 13.4021 220.581 12.8696C219.89 12.335 218.765 12.0687 217.21 12.0687H209.187V18.2733H217.21Z"
        fill={logoColor}
      ></path>
      <path
        d="M231.953 0.344727H249.905V21.2186H246.463V3.37478H235.255L234.856 12.4402C234.798 14.6508 234.52 16.4197 234.018 17.7448C233.514 19.0699 232.841 20.0194 231.999 20.5911C231.154 21.1629 230.126 21.4498 228.912 21.4498C228.571 21.4498 228.247 21.425 227.944 21.3776C227.64 21.3301 227.384 21.2785 227.176 21.2207V18.1328C227.213 18.1328 227.256 18.137 227.304 18.1473C227.351 18.1576 227.395 18.1617 227.432 18.1617C227.849 18.1803 228.181 18.1906 228.427 18.1906C229.092 18.1906 229.631 18.0296 230.048 17.7035C230.465 17.3795 230.797 16.7747 231.043 15.8892C231.289 15.0037 231.441 13.7488 231.499 12.1285L231.953 0.346789V0.344727Z"
        fill={logoColor}
      ></path>
      <path
        d="M254.91 21.2186V0.344727H272.065V3.31905H258.325V9.18099H270.872V12.0975H258.325V18.2175H272.606V21.2207H254.91V21.2186Z"
        fill={logoColor}
      ></path>
      <path
        d="M293.46 0.344727H296.93V21.2186H293.46V12.2977H279.746V21.2186H276.305V0.344727H279.746V9.26562H293.46V0.344727Z"
        fill={logoColor}
      ></path>
      <path
        d="M312.301 21.5612C309.93 21.5612 307.89 21.1319 306.185 20.2753C304.478 19.4167 303.179 18.2174 302.287 16.6715C301.395 15.1275 300.949 13.307 300.949 11.2099V10.3513C300.949 8.2542 301.395 6.43369 302.287 4.88977C303.179 3.34585 304.478 2.14456 306.185 1.28591C307.893 0.427261 309.93 0 312.301 0C314.671 0 316.766 0.429325 318.474 1.28591C320.181 2.14456 321.48 3.34379 322.372 4.88977C323.264 6.43369 323.71 8.2542 323.71 10.3513V11.2099C323.71 13.307 323.264 15.1275 322.372 16.6715C321.48 18.2154 320.181 19.4167 318.474 20.2753C316.766 21.134 314.708 21.5612 312.301 21.5612ZM312.358 18.5023C314.863 18.5023 316.791 17.8583 318.148 16.5724C319.504 15.2844 320.181 13.4804 320.181 11.1542V10.4111C320.181 8.06637 319.508 6.25618 318.162 4.97852C316.816 3.70087 314.881 3.06307 312.358 3.06307C309.835 3.06307 307.839 3.70706 306.484 4.99297C305.128 6.28095 304.451 8.08701 304.451 10.4111V11.1542C304.451 13.4804 305.128 15.2865 306.484 16.5724C307.841 17.8583 309.798 18.5023 312.358 18.5023Z"
        fill={logoColor}
      ></path>
      <path
        d="M146.105 41.2186C147.414 41.4663 148.566 42.005 149.561 42.8348C150.557 43.6645 151.054 44.775 151.054 46.1662C151.054 47.1962 150.769 48.1312 150.201 48.9692C149.632 49.8093 148.746 50.4656 147.542 50.9424C146.339 51.4192 144.825 51.6566 143.004 51.6566H131.426V30.7827H143.004C144.617 30.7827 145.985 31.0221 147.115 31.4969C148.242 31.9737 149.082 32.6259 149.632 33.4557C150.181 34.2854 150.457 35.2246 150.457 36.2731C150.457 37.1689 150.245 37.9595 149.817 38.6468C149.39 39.3341 148.845 39.8873 148.182 40.3042C147.517 40.7232 146.826 41.0102 146.105 41.1629V41.2186ZM142.891 33.6992H134.812V39.846H143.118C144.427 39.846 145.403 39.5736 146.047 39.0307C146.692 38.4879 147.014 37.7386 147.014 36.7871C147.014 35.8355 146.687 35.0347 146.033 34.5001C145.378 33.9676 144.33 33.6992 142.889 33.6992H142.891ZM142.891 48.7401C144.598 48.7401 145.81 48.4779 146.533 47.9537C147.253 47.4294 147.612 46.6636 147.612 45.6522C147.612 44.6986 147.253 43.9514 146.533 43.4086C145.812 42.8657 144.674 42.5933 143.118 42.5933H134.812V48.7401H142.891Z"
        fill={logoColor}
      ></path>
      <path
        d="M181.495 30.7834H185.365L176.518 46.4249C175.758 47.7975 175.043 48.875 174.37 49.6552C173.697 50.4375 172.938 51.0237 172.095 51.4138C171.251 51.8039 170.212 52 168.98 52C168.108 52 167.254 51.8864 166.42 51.6573V48.6541C167.235 48.7676 167.945 48.8254 168.552 48.8254C169.196 48.8254 169.733 48.7449 170.161 48.5819C170.588 48.4209 170.966 48.1629 171.298 47.8099C171.631 47.457 171.986 46.9471 172.366 46.2804L163.461 30.7814H167.528L174.527 43.4485L181.497 30.7814L181.495 30.7834Z"
        fill={logoColor}
      ></path>
      <path
        d="M205.07 51.6566L196.762 41.1629L191.954 45.5366V51.6566H188.539V30.7827H191.954V41.5922L203.873 30.7827H208.682L199.463 39.103L209.421 51.6566H205.068H205.07Z"
        fill={logoColor}
      ></path>
      <path
        d="M224.188 30.7833C225.914 30.7833 227.355 31.0372 228.511 31.5408C229.668 32.0465 230.537 32.7607 231.115 33.6854C231.693 34.6101 231.982 35.702 231.982 36.959C231.982 38.216 231.693 39.2749 231.115 40.1893C230.537 41.1036 229.668 41.824 228.511 42.3483C227.355 42.8725 225.912 43.1347 224.188 43.1347H216.165V51.6551H212.75V30.7812H224.186L224.188 30.7833ZM224.103 40.1624C225.621 40.1624 226.73 39.8858 227.432 39.3327C228.133 38.7795 228.484 37.989 228.484 36.959C228.484 35.929 228.133 35.1385 227.432 34.5853C226.73 34.0322 225.621 33.7556 224.103 33.7556H216.167V40.1604H224.103V40.1624Z"
        fill={logoColor}
      ></path>
      <path
        d="M253.594 51.6567H249.896L247.563 46.4243H237.321L235.016 51.6567H231.348L240.735 30.7828H244.093L253.594 51.6567ZM242.414 34.558L238.545 43.5656H246.341L242.414 34.558Z"
        fill={logoColor}
      ></path>
      <path
        d="M257.671 27.9367C257.291 28.3083 256.779 28.494 256.135 28.494C255.491 28.494 254.979 28.3083 254.599 27.9367C254.219 27.5652 254.031 27.074 254.031 26.4651C254.031 25.8562 254.221 25.3979 254.599 25.0347C254.979 24.6735 255.491 24.4918 256.135 24.4918C256.779 24.4918 257.291 24.6735 257.671 25.0347C258.051 25.3979 258.239 25.8747 258.239 26.4651C258.239 27.0554 258.049 27.5673 257.671 27.9367ZM260.97 30.7831H257.529V51.657H260.97V30.7831ZM263.943 27.9367C263.555 28.3083 263.047 28.494 262.422 28.494C261.757 28.494 261.237 28.3083 260.857 27.9367C260.477 27.5652 260.289 27.074 260.289 26.4651C260.289 25.8562 260.479 25.3979 260.857 25.0347C261.237 24.6735 261.757 24.4918 262.422 24.4918C263.087 24.4918 263.578 24.6735 263.958 25.0347C264.338 25.3979 264.528 25.8747 264.528 26.4651C264.528 27.0554 264.334 27.5673 263.943 27.9367Z"
        fill={logoColor}
      ></path>
      <path
        d="M283.268 30.7828H286.739V51.6567H283.268V42.7358H269.555V51.6567H266.113V30.7828H269.555V39.7037H283.268V30.7828Z"
        fill={logoColor}
      ></path>
      <path
        d="M295.184 51.6567H291.742V30.7828H295.184V51.6567Z"
        fill={logoColor}
      ></path>
      <path
        d="M19.0709 0.311768H0V51.6864H19.0709V0.311768Z"
        fill="#1367EA"
      ></path>
      <path
        d="M57.5748 0.311523H38.5039V51.6861H57.5748V0.311523Z"
        fill="#1367EA"
      ></path>
      <path
        d="M33.554 0.311523H24.0195V51.6861H33.554V0.311523Z"
        fill="#1367EA"
      ></path>
      <path
        d="M81.5943 0.311523H62.5234V51.6861H81.5943V0.311523Z"
        fill="#FCCC00"
      ></path>
      <path
        d="M120.11 0.311768H101.039V51.6864H120.11V0.311768Z"
        fill="#FCCC00"
      ></path>
      <path
        d="M96.0891 0.311523H86.5547V51.6861H96.0891V0.311523Z"
        fill="#FCCC00"
      ></path>
    </svg>
  );
}
