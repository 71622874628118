import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Input';
import FormHeader from 'view/components/Form/Header';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import { LIVE_HISTORY_PATH } from 'view/routes';
import useUser from 'hooks/user';
import { useTranslation } from 'react-i18next';
import CopyButton from 'view/components/CopyButton';
import IconButton from '@mui/material/IconButton';
import EyeOffIcon from 'view/components/icons/EyeOff';
import EyeIcon from 'view/components/icons/Eye';

type ViewLiveHistoryURLProps = {
  onCancel: () => void;
};

export default function ViewLiveHistoryURL({
  onCancel,
}: ViewLiveHistoryURLProps) {
  const { OBSUrl } = useUser();
  const { t } = useTranslation();
  const url = `${process.env.REACT_APP_UI_URL}/${OBSUrl}${LIVE_HISTORY_PATH}`;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <StyledFormContainer>
        <FormHeader
          title={t('Live history')}
          description={t('Add this link to the OBS Custom Browser Docks')}
        />
        <Box display="flex">
          <Box width="32rem" mr={1}>
            <Input
              label={t('Keep it secret and never share with anyone')}
              endAdornment={
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                </IconButton>
              }
              id="OBSurl"
              type={showPassword ? 'text' : 'password'}
              disabled
              value={url}
            />
          </Box>
          <CopyButton value={url} />
        </Box>

        <StyledFormActions>
          <Button
            label={t('Back')}
            variant="text"
            color="secondary"
            onClick={onCancel}
          />
        </StyledFormActions>
      </StyledFormContainer>
    </>
  );
}
