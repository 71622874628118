import { ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Button from 'view/components/Button';
import ConfirmationWarningIcon from 'view/components/icons/ConfirmationWarning';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogIcon,
} from './styled';
import { useTranslation } from 'react-i18next';

type ConfirmationDialogProps = {
  open: boolean;
  text: string;
  confirmText: string;
  color?: 'primary' | 'error';
  disabled?: boolean;
  loading?: boolean;
  withLoader?: boolean;
  icon?: ReactNode;
  children?: React.ReactNode;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
};

export default function ConfirmationDialog({
  open = false,
  text,
  icon,
  color = 'error',
  confirmText,
  onConfirm,
  onClose,
  children = null,
  disabled,
  loading,
  withLoader,
}: ConfirmationDialogProps) {
  const { t } = useTranslation();

  return (
    <StyledDialog
      open={open}
      aria-describedby="confirmation-dialog-description"
      onClose={onClose}
    >
      <StyledDialogIcon>
        {icon || <ConfirmationWarningIcon color={color} />}
      </StyledDialogIcon>
      <Box position="absolute" top={8} right={8}>
        <IconButton
          color="inherit"
          onClick={onClose}
          aria-label="clear-search"
          disableRipple
          disableFocusRipple
          sx={{
            '&.MuiButtonBase-root:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <ClearOutlinedIcon />
        </IconButton>
      </Box>
      <StyledDialogContent>
        <DialogContentText
          id="confirmation-dialog-description"
          typography="body1"
          sx={{ textAlign: 'center', fontWeight: 500, wordBreak: 'break-word' }}
          mb={2}
        >
          {text}
        </DialogContentText>
        {children}
      </StyledDialogContent>
      <StyledDialogActions disableSpacing>
        <Button
          type="submit"
          label={confirmText}
          color={color}
          disabled={disabled}
          loading={loading}
          onClick={onConfirm}
          fullWidth
          withLoader={withLoader}
        />
        <Button
          variant="text"
          color="secondary"
          disabled={disabled}
          loading={loading}
          label={t('Cancel')}
          withLoader={withLoader}
          onClick={onClose}
          fullWidth
        />
      </StyledDialogActions>
    </StyledDialog>
  );
}
